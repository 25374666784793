.paperMain{
    padding:1em;
    margin-top:1em
}

.bench{
        background-color: darkslateblue;

}

.active{
        background-color:darkgreen

}

.mix{
    background-color:darkcyan
}

.MuiContainer-root{
    margin-bottom: calc( 56px + 1em );
        -webkit-animation: fadein .5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein .5s; /* Firefox < 16 */
        -ms-animation: fadein .5s; /* Internet Explorer */
         -o-animation: fadein .5s; /* Opera < 12.1 */
            animation: fadein .5s;
}

.MuiToolbar-root .MuiButtonBase-root{
    color: inherit;
}
.appBarButton{
    flex-grow: 1!important;
    text-align: center;
}

.appBar{
    box-shadow:  0px 0px 5px #000!important;
}

.homeContainer{
    display: flex;
    flex-direction: column;
    height:calc(100dvh  - 56px - 1em)
}

.buttonContainer{
    flex-grow: 1;
    margin: 2em;
}

.MuiGrid-root{
    flex-direction: column!important;
}

.MuiAccordionDetails-root{
    border-top: 1px solid;
}

.fc-media-screen{
    margin-top: 1em;
}

html{
    background-color:rgba(0,0,0,0.05)
}

.Mui-expanded.MuiButtonBase-root{
    background-color: rgba(0,100,200,0.1)!important;
}

.MuiChip-root{
    margin-right: .25em;
}

.MuiCardContent-root{
    padding-bottom: 0px!important;
}

.tagContainer{
    padding-top: 8px;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}